import React, { useState, useContext } from "react";
import { Context } from "../Context/Context";
import { FaCircleCheck } from "react-icons/fa6";
import { FaTimesCircle } from "react-icons/fa";

function Alert() {
  const { mode, theme, alert, setAlert } = useContext(Context);
  return (
    alert.show && (
      <div
        className={`flex justify-center items-center top-0 left- z-50 fixed bg-black/10 backdrop-blur-[2px] h-screen w-full `}
      >
        <div
          className={`rounded-[10px] py-[3rem] px-[4rem] flex flex-col gap-[.6rem] items-center h-fit w-[36rem] ${mode?.ModeBgColor_100}`}
        >
          {alert.status === "success" ? (
            <FaCircleCheck className={`text-[6rem] ${theme.ThemTextColor}`} />
          ) : (
            <FaTimesCircle className={`text-[6rem] ${theme.ThemTextColor}`} />
          )}

          <h2
            className={`mt-[1rem] text-[1.5rem] font-bold ${mode?.ModeTextColor_900}`}
          >
            {alert.status === "success"
              ? " Thank you for messaging !"
              : "Something goes wrong"}
          </h2>
          <h2
            className={`text-[.98rem] text-center ${mode?.ModeTextColor_900}`}
          >
            {alert.status === "success"
              ? "Thank you for reaching out! I’ve received your message and will get back to you shortly. Looking forward to connecting!"
              : "Oops! It looks like there was an issue with sending your message. Please try again later, or feel free to reach out to me directly via email. I’ll get back to you as soon as possible!"}
          </h2>
          <button
            onClick={() =>
              setAlert({
                show: false,
                stats: null,
              })
            }
            className={`capitalize mt-[1rem] py-[.6rem] px-[2rem] text-[1.1rem] rounded-[4px] font-medium ${theme.ThemBGColor} text-white `}
          >
            Done
          </button>
        </div>
      </div>
    )
  );
}

export default Alert;
