const projectData = [
  {
    title: "MyCreditFunding",
    image: "./images/work-8-mycreaditfundeing.png",
    link: "https://www.mycreditfunding.com/",
  },
  {
    title: "Proforextrading",
    image: "./images/work-7-proforextrading.png",
    link: "https://proforextrading.vercel.app/",
  },
  {
    title: "Yesdoc healthcare",
    image: "./images/work-6-yesdocHealtcare.png",
    link: "https://www.yesdochealthcare.co.uk/",
  },
  {
    title: "Urbanfashion",
    image: "./images/shopnest.png",
    link: "https://urbanfashions.vercel.app/login",
  },
  {
    title: "Taskify",
    image: "./images/work-5-Taskify.png",
    link: "https://task-manager-mu-olive.vercel.app/login",
  },
  {
    title: "client Portfolio",
    image: "./images/work-4-clientPortfolio.png",
    link: "https://client-portfolio-by-dhrumitpanchal.netlify.app/",
  },
  {
    title: "caffeine",
    image: "./images/work-1-caffine.png",
    link: "https://caffeine-coffee.netlify.app/",
  },
  {
    title: "MilanStar",
    image: "./images/work-2-milanstar.png",
    link: "https://game-matka.netlify.app/",
  },
  {
    title: "Tom Flether",
    image: "./images/work-3-TomFlether.png",
    link: "https://tom-flather-dk.netlify.app/",
  },
];

export default projectData;
