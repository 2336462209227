import { createContext, useState, useEffect } from "react";
export const Context = createContext(null);

export default function MyContext(props) {
  // for theme color --------------------
  const [theme, setTheme] = useState({
    ThemTextColor: "text-[#ec1839]",
    ThemBGColor: "bg-[#ec1839]",
    ThemBorderColor:
      "after:border-[#ec1839] before:border-[#ec1839] border-[#ec1839]",
    ThemHoverText: "hover:text-[#ec1839]",
  });

  // for lite & dark mode ---------------
  const [liteMode, setLiteMode] = useState(true);
  const [mode, setMode] = useState({
    ModeBgColor_900: "bg-[#f2f2fc]",
    ModeBgColor_100: "bg-[#fdf9ff]",
    ModeBgColor_50: "bg-[#e8dfec]",
    ModeBorderColor_50: "border-[#e8dfec]",
    ModeTextColor_900: "text-[#302e4d]",
    ModeTextColor_700: "text-[#504e70]",
  });

  // form alert ----------------------------------

  const [alert, setAlert] = useState({
    show: false,
    stats: null,
  });

  useEffect(() => {
    switch (liteMode) {
      case true:
        setMode({
          ModeBgColor_900:
            "bg-[#f2f2fc] after:bg-[#f2f2fc] before:bg-[#f2f2fc]",
          ModeBgColor_100:
            "bg-[#fdf9ff] after:bg-[#fdf9ff] before:bg-[#fdf9ff]",
          ModeBgColor_50:
            "bg-[#e8dfec]  after:bg-[#e8dfec] before:bg-[#e8dfec]",
          ModeBorderColor_50: "border-[#e8dfec]",
          ModeTextColor_900: "text-[#302e4d]",
          ModeTextColor_700: "text-[#fdf9ff]",
        });
        break;

      case false:
        setMode({
          ModeBgColor_900:
            "bg-[#151515] after:bg-[#151515] before:bg-[#151515]",
          ModeBgColor_100:
            "bg-[#222222] after:bg-[#222222] before:bg-[#222222]",
          ModeBgColor_50: "bg-[#393939] after:bg-[#393939] before:bg-[#393939]",
          ModeBorderColor_50: "border-[#393939]",
          ModeTextColor_900: "text-[#ffffff]",
          ModeTextColor_700: "text-[#e9e9e9]",
        });
        break;

      default:
        setMode({
          ModeBgColor_900:
            "bg-[#f2f2fc] after:bg-[#f2f2fc] before:bg-[#f2f2fc]",
          ModeBgColor_100:
            "bg-[#fdf9ff] after:bg-[#fdf9ff] before:bg-[#fdf9ff]",
          ModeBgColor_50:
            "bg-[#e8dfec]  after:bg-[#e8dfec] before:bg-[#e8dfec]",
          ModeBorderColor_50: "border-[#e8dfec]",
          ModeTextColor_900: "text-[#302e4d]",
          ModeTextColor_700: "text-[#fdf9ff]",
        });
    }
  }, [liteMode]);

  const themeColor = (color) => {
    switch (color) {
      case "red":
        setTheme({
          ThemTextColor: "text-[#ec1839]",
          ThemBGColor: "bg-[#ec1839]",
          ThemBorderColor:
            "after:border-[#ec1839] before:border-[#ec1839] border-[#ec1839]",
          ThemHoverText: "hover:text-[#ec1839]",
        });
        break;

      case "orange":
        setTheme({
          ThemTextColor: "text-[#fa5b0f]",
          ThemBGColor: "bg-[#fa5b0f]",
          ThemBorderColor:
            "after:border-[#fa5b0f] before:border-[#fa5b0f] border-[#fa5b0f]",
          ThemHoverText: "hover:text-[#fa5b0f]",
        });
        break;

      case "green":
        setTheme({
          ThemTextColor: "text-[#37b182]",
          ThemBGColor: "bg-[#37b182]",
          ThemBorderColor:
            "after:border-[#37b182] before:border-[#37b182] border-[#37b182]",
          ThemHoverText: "hover:text-[#37b182]",
        });
        break;

      case "blue":
        setTheme({
          ThemTextColor: "text-[#1854b4]",
          ThemBGColor: "bg-[#1854b4]",
          ThemBorderColor:
            "after:border-[#1854b4] before:border-[#1854b4] border-[#1854b4]",
          ThemHoverText: "hover:text-[#1854b4]",
        });
        break;
      case "pink":
        setTheme({
          ThemTextColor: "text-[#f021b2]",
          ThemBGColor: "bg-[#f021b2]",
          ThemBorderColor:
            "after:border-[#f021b2] before:border-[#f021b2] border-[#f021b2]",
          ThemHoverText: "hover:text-[#f021b2]",
        });
        break;

      default:
        setTheme({
          ThemTextColor: "text-[#ec1839]",
          ThemBGColor: "bg-[#ec1839]",
          ThemBorderColor:
            "after:border-[#ec1839] before:border-[#ec1839] border-[#ec1839]",
          ThemHoverText: "hover:text-[#ec1839]",
        });
    }
  };
  return (
    <Context.Provider
      value={{
        liteMode,
        setLiteMode,
        mode,
        theme,
        setTheme,
        themeColor,
        alert,
        setAlert,
      }}
    >
      {props.children}
    </Context.Provider>
  );
}
